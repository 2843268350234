import React from 'react';
import { Couponer, Coupon, Error } from './definition';

type Hook = (options: HookOptions) => [State, Action];

export type Action = {
  update(code: string): void;
  validate(productIDs: string[]): void;
};

export type State =
  | { status: 'READY' | 'PENDING'; code: string; coupon: Coupon | null }
  | { status: 'ERROR'; code: string; coupon: Coupon | null; error: Error };

export type HookOptions = {
  couponer: Couponer;
};

const useCoupon: Hook = ({ couponer }) => {
  const [state, setState] = React.useState<State>({
    status: 'READY',
    code: '',
    coupon: null,
  });

  const update = (code: string) =>
    setState(({ coupon }) => ({
      code: code.toUpperCase(),
      status: 'READY',
      coupon,
    }));

  const validate = (productIDs: string[]) => {
    if (state.code && state.status !== 'PENDING') {
      setState((prevState) => ({
        ...prevState,
        status: 'PENDING',
      }));

      couponer
        .get({ code: state.code, productIDs })
        .then((coupon) => {
          setState((prevState) => ({
            ...prevState,
            status: 'READY',
            code: '',
            coupon,
          }));
        })
        .catch((error: Error) => {
          setState((prevState) => ({
            ...prevState,
            status: 'ERROR',
            coupon: null,
            error,
          }));
        });
    }
  };

  return [
    state,
    {
      update,
      validate,
    },
  ];
};

export default useCoupon;
