import { Requester } from '../http/definition';
import defaultRequester from '../http/requester';
import { Session, SessionerCreateOptions } from './definition';

type Options = SessionerCreateOptions & {
  requester?: Requester<Session, SessionerCreateOptions>;
};

const create = ({ items, code, requester = defaultRequester }: Options) =>
  requester({
    method: 'POST',
    endpoint: '/.netlify/functions/checkout',
    body: {
      items,
      code,
    },
  });

export default create;
