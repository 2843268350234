import { Requester } from '../http/definition';
import defaultRequester from '../http/requester';
import { Coupon, CouponerGetOptions } from './definition';

type Options = CouponerGetOptions & {
  requester?: Requester<Coupon, CouponerGetOptions>;
};

const get = ({ code, productIDs, requester = defaultRequester }: Options) =>
  requester({
    method: 'GET',
    endpoint: '/.netlify/functions/coupon',
    params: {
      productIDs: productIDs.join(','),
      code,
    },
  });

export default get;
