import { Options } from './definition';

const requester = <T = {}, U = {}>(options: Options<U>): Promise<T> => {
  const endpoint = window.location.origin + options.endpoint;
  const params: Partial<RequestInit> = {
    method: options.method,
  };

  if (options.method === 'POST' && options.body) {
    params.body = JSON.stringify(options.body);
  }

  return fetch(`${endpoint}${encode(options.params)}`, params).then(
    (response) =>
      response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject({
            status: response.status,
            body: json,
          });
        }

        return json;
      })
  );
};

const encode = (params: Options['params'] = {}): string => {
  return Object.keys(params).reduce((acc, key, index) => {
    const prefix = index === 0 ? '?' : '&';
    return acc + `${prefix}${key}=${params[key]}`;
  }, '');
};

export default requester;
