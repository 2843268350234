import React from 'react';

type Props = React.SVGAttributes<HTMLOrSVGElement>;

const Close: React.FC<Props> = (props) => (
  <svg
    height="100%"
    width="100%"
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m590.711604 18.2982556c-.384528-.3845279-1.005688-.3845279-1.390216 0l-4.821388 4.8115282-4.821388-4.8213879c-.384528-.3845279-1.005688-.3845279-1.390216 0s-.384528 1.0056883 0 1.3902162l4.821388 4.8213879-4.821388 4.8213879c-.384528.3845279-.384528 1.0056883 0 1.3902162s1.005688.3845279 1.390216 0l4.821388-4.8213879 4.821388 4.8213879c.384528.3845279 1.005688.3845279 1.390216 0s.384528-1.0056883 0-1.3902162l-4.821388-4.8213879 4.821388-4.8213879c.374668-.3746682.374668-1.0056883 0-1.3803565z"
      transform="translate(-578 -18)"
    />
  </svg>
);

export default Close;
