import React from 'react';
import { Sessioner, Provider, SessionerCreateOptions } from './definition';

type Hook = (options: HookOptions) => [State, Action];

export type Action = (options: SessionerCreateOptions) => void;

export type State = {
  status: 'READY' | 'PENDING' | 'ERROR' | 'CANCEL';
};

export type HookOptions = {
  provider: Provider | null;
  sessioner: Sessioner;
};

const CANCEL_TOKEN = 'annulation';

const useCheckout: Hook = ({ provider, sessioner }) => {
  const [status, setStatus] = React.useState<State['status']>('READY');

  React.useEffect(() => {
    if (window.location.search.indexOf(CANCEL_TOKEN) !== -1) {
      setStatus('CANCEL');
    }
  }, []);

  const handler = ({ items, code }: SessionerCreateOptions): void => {
    if (provider && status !== 'PENDING') {
      setStatus('PENDING');

      sessioner
        .create({ items, code })
        .then((session) =>
          provider.checkout(session).then((response) => {
            if (response.error) {
              return Promise.reject(response.error);
            }

            return response;
          })
        )
        .catch(() => {
          // TODO
          // - StripeError
          // - Response 4xx, 5xx
          setStatus('ERROR');
        });
    }
  };

  return [
    {
      status,
    },
    handler,
  ];
};

export default useCheckout;
