import { Stripe } from '@stripe/stripe-js';
import { Provider } from './definition';

const provider = (stripe: Stripe | null): Provider | null => {
  if (!stripe) {
    return null;
  }

  return {
    checkout({ id }) {
      return stripe.redirectToCheckout({
        sessionId: id,
      });
    },
  };
};

export default provider;
