import React from 'react';
import { Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import useCheckout from './hook';

type Props = {
  stripe: Promise<Stripe | null>;
};

const Provider: React.FC<Props> = ({ stripe, children }) => (
  <Elements stripe={stripe}>{children}</Elements>
);

export default {
  Provider,
  useCheckout,
};
