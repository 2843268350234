import React from 'react';
import cx from 'classnames';
import Close from '../Icon/Close';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<Props> = ({ className, children, ...props }) => (
  <button
    {...props}
    className={cx(
      'flex appearance-none bgc-transparent w-15x h-15x brw-0 pdt-0 pdr-0 pdb-0 pdl-0 outline-none hover:cursor-pointer hover:orange focus:orange',
      className
    )}
  >
    <Close className="coral fill-current" />
  </button>
);

export default Button;
