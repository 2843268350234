import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import SEO from '../../../components/SEO';
import Layout from '../../../components/Layout/Layout';
import Section from '../../../components/Section/Section';
import Select from '../../../components/Select/Select';
import Button from '../../../components/Button/Button';
import Close from '../../../components/Button/Close';
import CSROnly from '../../../components/CSROnly/CSROnly';
import Mailto from '../../../components/Mailto';
import * as price from '../../../modules/product/price';
import * as selector from '../../../modules/cart/selector';
import { MAX_QUANTITY } from '../../../modules/cart/reducer';
import Cart from '../../../modules/cart/Cart';
import Checkout from '../../../modules/checkout/Checkout';
import create from '../../../modules/checkout/create';
import provider from '../../../modules/checkout/provider';
import Coupon from '../../../modules/coupon/Coupon';
import get from '../../../modules/coupon/get';
import track from '../../../modules/analytics/track';

const stripe = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_API_KEY!);

const CartPage: React.FC = () => {
  const stripe = useStripe();
  const [checkout, checkoutAction] = Checkout.useCheckout({
    provider: provider(stripe),
    sessioner: {
      create,
    },
  });

  const [cart, cartAction] = Cart.useCart();
  const items = selector.getCartItems(cart);
  const total = selector.getTotalItems(cart);
  const shipping = selector.getShippingItems(cart);
  const isEmpty = !items.length;

  const [coupon, couponAction] = Coupon.useCoupon({
    couponer: {
      get,
    },
  });

  const amountCoupon = selector.getAmountCoupon(cart, total);
  const totalWithCoupon = selector.getTotalCoupon(cart, total);
  const totalWithShipping = totalWithCoupon + shipping;

  React.useEffect(() => {
    if (checkout.status === 'CANCEL') {
      const endpoint = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, endpoint);
    }
  }, [checkout.status]);

  React.useEffect(() => {
    if (items.length === 0) {
      // Resets the input value in case we remove all items from the cart. It prevents
      // the input to be disabled with a value impossible to clear. It also reset
      // the status which removes the error.
      couponAction.update('');
    }
  }, [items.length]);

  React.useEffect(() => {
    if (coupon.coupon) {
      // FIXME
      cartAction({ type: 'COUPON_ADD', coupon: coupon.coupon });
    }
    // FIXME
  }, [coupon.coupon]);

  const onProductRemove = (id: string) => {
    cartAction({ type: 'REMOVE', id });
    track({ category: 'cart', action: 'cart_remove', label: id });
  };

  const onProductChange = (id: string, quantity: number) => {
    cartAction({ type: 'UPDATE', id, quantity });
    track({ category: 'cart', action: 'cart_update', label: id });
  };

  const onCouponRemove = () => {
    cartAction({ type: 'COUPON_REMOVE' });
  };

  const onCouponChange = (input: string) => {
    couponAction.update(input);
  };

  const onCouponSubmit = () => {
    couponAction.validate(items.map((item) => item.product.variant.id));
  };

  const onClick = () => {
    checkoutAction({
      code: cart.coupon?.id,
      items: items.map((item) => ({
        id: item.product.variant.id,
        name: item.product.name,
        description: item.product.variant.name,
        quantity: item.quantity,
        image:
          window.location.origin +
          item.product.variant.images[0].childImageSharp.fluid.src,
      })),
    });
  };

  return (
    <Layout
      title="Votre panier"
      crumbs={[
        { name: 'Accueil', path: '/' },
        { name: 'Shop', path: '/shop/' },
        { name: 'Panier', path: '/panier/' },
      ]}
    >
      <SEO title="Panier | Poupée Odette" robots="noindex, nofollow" />

      <CSROnly fallback={<div className="h-366x" />}>
        <Section>
          {checkout.status === 'CANCEL' && (
            <div className="mw-980x mrr-auto mrl-auto mrb-15x pdr-10x pdl-10x">
              <p className="brr-4x bgc-pink ta-center fsz-md fw-bold white pdt-10x pdr-15x pdb-10x pdl-15x">
                Commande annulée, si vous avez des questions, n’hésitez pas à
                nous contacter à{' '}
                <Mailto className="white" address="hello@poupeeodette.fr">
                  hello@poupeeodette.fr
                </Mailto>
              </p>
            </div>
          )}
          <div className="mw-980x mrr-auto mrl-auto pdr-10x pdl-10x md:flex">
            <ul className="md:flex-175 md:mrr-20x">
              {isEmpty ? (
                <li className="flex items-center justify-center bgc-white mrb-15x brw-1x brs-s brc-e7e7e7 brr-4x bs-1x-4x pdt-15x pdr-15x pdb-15x pdl-15x h-full mh-150px">
                  <p className="w-10/12 ta-center fsz-md fw-bold">
                    Rendez-vous dans le{' '}
                    <Link className="orange" to="/shop/">
                      shop
                    </Link>{' '}
                    pour choisir votre poupée !
                  </p>
                </li>
              ) : (
                items.map((item) => (
                  <li
                    key={item.product.variant.id}
                    className="bgc-white mrb-15x brw-1x brs-s brc-e7e7e7 brr-4x bs-1x-4x pdt-15x pdr-15x pdb-15x pdl-15x"
                  >
                    <div className="flex">
                      <Image
                        className="flex-075 mw-165x mh-165px mrr-20x"
                        fluid={
                          item.product.variant.images[0].childImageSharp.fluid
                        }
                      />
                      <div className="flex flex-1 flex-column justify-between">
                        <div>
                          <p className="flex w-full justify-between items-center ff-lora fsz-lg fsy-italic blue mrb-5x">
                            <span>{item.product.name}</span>
                            <Close
                              onClick={() =>
                                onProductRemove(item.product.variant.id)
                              }
                            />
                          </p>
                          {item.product.variant.name && (
                            <p className="fsz-md mrb-5x">
                              {item.product.variant.name}
                            </p>
                          )}
                          <p className="fsz-md mrb-10x fw-bold">
                            {price.format(item.product.variant.price)} €
                          </p>
                          <Select
                            value={item.quantity}
                            onChange={(event) =>
                              onProductChange(
                                item.product.variant.id,
                                parseInt(event.currentTarget.value, 10)
                              )
                            }
                          >
                            {Array.from(
                              { length: MAX_QUANTITY },
                              (_, idx) => idx + 1
                            ).map((value) => (
                              <option key={value} value={value}>
                                Quantité : {value}
                              </option>
                            ))}
                          </Select>
                        </div>
                        <div className="fsz-md fw-bold tt-upper">
                          Sous-total :{' '}
                          {price.format(selector.getTotalItem(item))} €
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
            <div className="flex-1">
              <div className="bgc-white brw-1x brs-s brc-e7e7e7 brr-4x bs-1x-4x pdt-15x pdr-15x pdb-15x pdl-15x">
                <p className="ff-lora fsz-lg fsy-italic tt-upper blue mrb-25x">
                  Total commande
                </p>
                <div>
                  <p className="flex mrb-15x justify-between tt-upper fsz-md">
                    <span className="fw-bold">Sous-total</span>
                    <span>{price.format(total)} €</span>
                  </p>
                  <p className="flex mrb-5x justify-between tt-upper fsz-md">
                    <span className="fw-bold">Livraison colissimo</span>
                    <span>{!isEmpty ? price.format(shipping) : 0} €</span>
                  </p>
                  <p className="fsz-sm fw-light fsy-italic mrb-15x">
                    Nous sommes encore trop petits pour offrir la
                    <br /> livraison - pourtant nous aimerions !
                  </p>
                  {!isEmpty && cart.coupon ? (
                    <React.Fragment>
                      <p className="flex justify-between tt-upper fsz-md">
                        <span className="fw-bold">{cart.coupon.id}</span>
                        <span>-{price.format(amountCoupon)} €</span>
                      </p>
                      <button
                        className="apparence-none bgc-transparent br-none outline-none pdt-0 pdr-0 pdb-0 pdl-0 mrb-35x orange fsz-sm fw-bold fsy-italic tt-upper hover:cursor-pointer"
                        onClick={onCouponRemove}
                      >
                        Supprimer
                      </button>
                    </React.Fragment>
                  ) : (
                    <form
                      action=""
                      className="bgc-skin brr-4x pdt-15x pdr-10x pdb-15x pdl-10x mrb-35x"
                      onSubmit={(event) => {
                        event.preventDefault();
                        onCouponSubmit();
                      }}
                    >
                      <div className="flex">
                        <input
                          className={cx(
                            'brw-1x brr-4x brs-s brc-transparent outline-none appearance-none w-full pdt-10x pdr-10x pdb-10x pdl-10x mrr-10x fsz-sm fw-bold tt-upper pink focus:brc-orange',
                            coupon.status === 'ERROR' && 'brc-error'
                          )}
                          type="text"
                          value={!isEmpty ? coupon.code : ''}
                          spellCheck={false}
                          autoCorrect="off"
                          autoComplete="off"
                          placeholder="Code"
                          disabled={isEmpty}
                          onChange={(event) =>
                            onCouponChange(event.currentTarget.value)
                          }
                        />
                        <Button
                          type="submit"
                          disabled={coupon.status === 'PENDING' || isEmpty}
                        >
                          Ok
                        </Button>
                      </div>
                      {coupon.status === 'ERROR' && (
                        <p className="fsy-italic fsz-sm fw-bold orange pdt-5x">
                          Code invalide.
                        </p>
                      )}
                    </form>
                  )}
                </div>
                <Button
                  className={cx(
                    'w-full mrb-20x overflow-hidden',
                    checkout.status === 'PENDING' && 'btn-static'
                  )}
                  disabled={!stripe || isEmpty}
                  onClick={onClick}
                >
                  Paiement
                  {!isEmpty && ` ${price.format(totalWithShipping)} €`}
                  {checkout.status === 'PENDING' && (
                    <span className="block absolute w-full h-full top-0 left-0 orange bgc-current translate-x-95" />
                  )}
                </Button>
                {checkout.status === 'ERROR' && (
                  <div className="fsz-md orange">
                    Oups ! Une erreur est survenue, veuillez réessayer svp.
                  </div>
                )}
              </div>
            </div>
          </div>
        </Section>
      </CSROnly>
    </Layout>
  );
};

const CartPageWithCheckout = () => (
  <Checkout.Provider stripe={stripe}>
    <CartPage />
  </Checkout.Provider>
);

export default CartPageWithCheckout;
